import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import PageDescription from "../components/PageDescription"
import ProductColumnGenerator from "../components/ProductColumnGenerator"
import Seo from "../components/Seo"
import { DEFAULT_IMG_URL } from "../constants"
import { useHierarchy } from "../context/hierarchy-context"

const IndexPage = ({ data }) => {
  const { hierarchy } = useHierarchy()
  return (
    <Layout
      breadcrumbs={false}
      fullWidth
      pageTitle="We create value-driven solutions tailored to your needs"
      banners={{
        data: [
          {
            image: "https://res.cloudinary.com/dthskrjhy/image/upload/v1663342224/Typenex/new-website-images/Home%20Banners/aabb-2022-banner-1.jpg",
            link: "/products/laboratory/"
          },
          {
            image: "https://res.cloudinary.com/dthskrjhy/image/upload/v1658490645/Typenex/new-website-images/Home%20Banners/Breakthroughs-2022-Banner-General-embedded-copy.jpg",
            link: "/products/patient-id/"
          },
          {
            image: "https://res.cloudinary.com/dthskrjhy/image/upload/v1658490384/Typenex/new-website-images/Home%20Banners/breakthroughs-2022-banner-2-embedded-copy.jpg",
            link: "/products/anesthesia/breathing-circuit-filters/hmes-and-filters/"
          },
          {
            image: "https://res.cloudinary.com/dthskrjhy/image/upload/v1658490248/Typenex/new-website-images/Home%20Banners/breakthroughs-2022-banner-3-embedded-copy.jpg",
            link: "/products/floor-fluid-management/"
          },
          {
            image: "https://res.cloudinary.com/dthskrjhy/image/upload/v1658490539/Typenex/new-website-images/Home%20Banners/cassettes-home-banner-embedded-copy.jpg",
            link: "/products/laboratory/histology-cassettes/"
          }
        ],
        name: "Home banner",
      }}
    >
      <Seo
        title="Typenex Medical | Blood Bands - Blood Dispensers - Patient Verification"
        description="Offering various blood bands, blood dispensers, TypeSafe, Smearsafe & Final Check. Contact us today & get a free quote."
      />
      <PageDescription description={
        `Typenex® Medical is a medical device company that gives hospitals, 
        clinics, and surgery centers the tools they need to provide better care. 
        Since 2004, we have developed and offered affordable medical solutions that 
        improve the experiences of clinicians and patients alike.`
      } />
      <div className="my-5">
        <Link to="/about/" className="typenex-btn">About Us</Link>
      </div>
      <h2>Explore our areas of speciality</h2>
      <ProductColumnGenerator
        array={hierarchy?.children.map(node => {
          let pageDetails = data.allWebpagesJson.nodes.find(details => details.webpageId === node.webpage_id)
          return {
            img: (node.images.length > 0 && node.images[0].image) || DEFAULT_IMG_URL,
            url: `/products/${node.url}`,
            name: node.name,
            description: pageDetails?.thumbnailDescription
          }
        })}
      />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
 query {
   allWebpagesJson {
     nodes {
       webpageId
       thumbnailDescription
     }
   }
 }
`

